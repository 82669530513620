import { createElement, FC, useEffect } from 'react';
import { AnalyticsEvent, useSendAnalytics } from 'packages/react-analytics';
import { FormSpy } from 'react-final-form';
import { useHttpQueryWithLoadingWithErrors } from 'packages/http-client/react';
import { createAnalyticsIdsRequest } from 'apps/acp/packages/webapi';

export * from './page-view';

/**
 * Quantum Metrics is configured to listen for the /v2/analytics/id endpoint on certain pages
 * and capture the external_id result.  The external_id is used for Snowflake-related reporting.
 * QM's sessions & live replays are defined by browser/devices, so the external_id is a piece
 * of captured data to QM.
 */
export const useStartQmAnalyticsSession = () => {
  // QM listens for this API call, but it shouldn't block other functionality, especially if it
  // fails.  Thus, "handling" the loading and errors ensures this API call is non-blocking.
  useHttpQueryWithLoadingWithErrors(createAnalyticsIdsRequest());
};

/**
 * Sends an analytics event once when the calling component is being first rendered
 *
 * TODO (for the next person who wants to use this): turn into <SendEventAnalytics event={} />
 */
export const useSendAnalyticsOnce = (analyticsEvent: AnalyticsEvent) => {
  const sendAnalytics = useSendAnalytics();
  useEffect(() => {
    sendAnalytics(analyticsEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/**
 * <SendEventAnalytics event={} /> component created.
 * Sends an analytics event once when the calling component is being first rendered
 */
export const SendEventAnalytics: FC<{ analyticsEvent: AnalyticsEvent }> = ({
  analyticsEvent
}) => {
  const sendAnalytics = useSendAnalytics();
  useEffect(() => {
    sendAnalytics(analyticsEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

interface SendAnalyticsEventProps {
  analyticsEvent: AnalyticsEvent;
}

/**
 * Sends an analytics event when form submit successful.
 */
export const FormSubmitSuccessAnalyticsEvent: FC<SendAnalyticsEventProps> = ({
  analyticsEvent
}) => {
  const sendAnalytics = useSendAnalytics();
  return (
    <FormSpy
      subscription={{ submitSucceeded: true }}
      onChange={({ submitSucceeded }) => {
        if (submitSucceeded) {
          sendAnalytics(analyticsEvent);
        }
      }}
    />
  );
};

/**
 * Sends an analytics event when submitting the form.
 */
export const FormSubmittingAnalyticsEvent: FC<SendAnalyticsEventProps> = ({
  analyticsEvent
}) => {
  const sendAnalytics = useSendAnalytics();
  return (
    <FormSpy
      subscription={{ submitting: true }}
      onChange={({ submitting }) => {
        if (submitting) {
          sendAnalytics(analyticsEvent);
        }
      }}
    />
  );
};

/**
 * Sends an analytics event when form submit fails or errors occur.
 */
export const FormSubmitFailedErrorAnalyticsEvent: FC<SendAnalyticsEventProps> = ({
  analyticsEvent
}) => {
  const sendAnalytics = useSendAnalytics();
  return (
    <FormSpy
      subscription={{ submitErrors: true, submitFailed: true }}
      onChange={({ submitErrors, submitFailed }) => {
        if (submitErrors || submitFailed) {
          sendAnalytics(analyticsEvent);
        }
      }}
    />
  );
};

/**
 * Sends an analytics event when form value changes eg. like changeing value of checkbox
 */
export const SendAnalyticsOnFormValueChange: FC<{
  value: string;
  analyticsEvent: AnalyticsEvent;
}> = ({ value, analyticsEvent }) => {
  const sendAnalytics = useSendAnalytics();

  return (
    <FormSpy
      subscription={{ values: true }}
      onChange={(props) => {
        if (props.values[value]) {
          sendAnalytics(analyticsEvent);
        }
      }}
    />
  );
};

/**
 * Sends an analytics event when user click on input field
 */
export const SendAnalyticsOnInputFieldClicked: FC<{
  analyticsEvent: Record<string, AnalyticsEvent>;
}> = ({ analyticsEvent }) => {
  const sendAnalytics = useSendAnalytics();

  return (
    <FormSpy
      subscription={{ values: true, active: true }}
      onChange={({ active, values }) => {
        if (active && !values[active] && analyticsEvent[active]) {
          sendAnalytics(analyticsEvent[active]);
        }
      }}
    />
  );
};

import { useAcpLocalStorage } from 'apps/acp/packages/use-acp-storage';
import { useSendAnalytics } from 'packages/react-analytics';
import { useLocation } from 'packages/react-nano-router';
import { useEffect } from 'react';

// Calls `sendAnalytics()` when `location.pathname` changes
export const useSendPageViewsOnRouteChange = (
  mfeName?: string,
  indexRouteName = 'index'
) => {
  const location = useLocation();
  const sendAnalytics = useSendAnalytics();
  const [accountId] = useAcpLocalStorage('account_id', '');

  useEffect(() => {
    const [mfeNameViaUrl, pageName] = getMfeAndPageFromPathname(
      location.pathname
    );
    const page = pageName || indexRouteName;
    const mfe = mfeName || mfeNameViaUrl;

    sendAnalytics({
      event: 'nsPageView',
      payload: {
        nsPageView_mfe: mfe,
        nsPageView_title: page,
        nsPageView_page: `${mfe}/${page}`,
        nsPageView_accountId: accountId
      }
    });
  }, [mfeName, indexRouteName, location.pathname, accountId, sendAnalytics]);
};

// Calls `sendAnalytics()` once (unless parameters change value)
export const useSendPageView = (mfe: string, page: string) => {
  const sendAnalytics = useSendAnalytics();
  const [accountId] = useAcpLocalStorage('account_id', '');

  useEffect(() => {
    sendAnalytics({
      event: 'nsPageView',
      payload: {
        nsPageView_mfe: mfe,
        nsPageView_title: page,
        nsPageView_page: `${mfe}/${page}`,
        nsPageView_accountId: accountId
      }
    });
  }, [mfe, page, accountId, sendAnalytics]);
};

const getMfeAndPageFromPathname = (pathname: string) => {
  const baselessPath = pathname.replace('/account/', '');
  const mfeMatch = baselessPath.match(/^[^/]*/); // capture everything before the first slash /
  const mfeNameViaUrl = mfeMatch && mfeMatch[0];
  const page = baselessPath.replace(`${mfeNameViaUrl}`, '').replace('/', '');
  return [mfeNameViaUrl, page] as const;
};
